import React from "react"
import dataInfo from "../Data"

export default function SkillSet(){
    const containerStyling = {
        borderRadius: "20px",
        margin: "1.5em 1em",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",
        padding: "1em"
    }

    const skillStyling = {
        width: "max-content",
        margin: "0.75em 0.75em 0.75em 0",
        border: "1px solid",
        borderRadius: "25px"
    }

    const infoStyling = {
        padding: "0.75em 1.5em",
        margin: "0",
        fontWeight: "bold"
    }

    const listOfSkills = dataInfo.personalDetails.education.completeSkillSet.map(skill => (
        <div key={skill} style={skillStyling}>
            <p style={infoStyling}>{skill}</p>
        </div>
    ))

    return (
        <div className="skill-set-component" style={containerStyling}>
            <h3 style={{width:"100%"}}>Skills</h3>
            {listOfSkills}
        </div>
    )
}