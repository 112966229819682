import React from "react"
import { Link } from "react-router-dom"
import SingleProject from "./SingleProject"
import dataInfo from "../Data"

export default function ProjectsLayout() {
    const containerStyling = {
        padding:"1em",
        borderRadius: "20px",
        margin: "1em",
    }

    const linkStyling = {
        textDecoration: "none",
        color: "inherit",
        "&:visited": {
            color: "inherit", 
        }
    }

    return ( 
        <div className="project-layout-section" style={containerStyling}>
          <h3>Latest Work</h3>
          {dataInfo.projects.map((project) => (
            <Link key={project.title} to={`/${project.id}`} style={linkStyling}>
              <SingleProject 
                id={project.id} 
                image={process.env.PUBLIC_URL + "/assets/" + project.image} 
                title={project.title} 
              />
            </Link>
          ))}
        </div>
    )
}