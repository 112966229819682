import React from "react"
import Header from "../components/Header"
import MainTitle from "../components/MainTitle"
import About from "../components/About"
import Profile from "../components/Profile"
import Education from "../components/Education"
import SkillSet from "../components/SkillSet"
import Footer from "../components/Footer"
import ProjectsLayout from "../components/ProjectsLayout"

export default function Landing() {
    
    return (
        <div className="landing-section">
            <Header />
            <MainTitle />
            <section className="personal-detail-section">
                <About />
                <Profile />
            </section>
            <ProjectsLayout />
            <section className="credential-section">
                <SkillSet />
                <Education />
            </section>
            <Footer />
        </div>
    )
}
