import React from "react"
import { useParams, Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons"
import dataInfo from "../Data"

export default function ProjectDisplay() {
  const { projectTitle } = useParams() // Get project title from URL parameters
  const project = dataInfo.projects.find(project => project.id === projectTitle)

  const containerStyling = {
    padding: "1em",
    borderRadius: "20px",
    margin: "1em",
  }

  const linkStyling = {
    textDecoration: "none",
    color: "inherit",
    "&:visited": {
        color: "inherit", 
      },
    cursor: "pointer",
    width: "100%",
    display: "block"
  }

  const infoStyling = {
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  }

  const imageStyling = {
    borderRadius: "20px",
    width: "300px",
    margin: "2em auto",
    boxShadow: "1px 1px 8px #0c3b2e"
  }

  const skillStyling = {
    width: "100%",
    marginBottom: "2em"
  }

  return (
    <div className="project-display-section" style={containerStyling}>
        <Link to="../" className="back-button" style={linkStyling}>
           &larr; Back
        </Link>

        <img src={process.env.PUBLIC_URL + "/assets/" + project.image} 
            alt={`${project.title}'s UI`} style={imageStyling} />
        <div style={infoStyling} className="project-display-section-details">
            <h3 style={{width:"100%"}}>{project.title}</h3>
            <p style={{width:"100%"}}>{project.description}</p>
            <p style={skillStyling}>Technologies: {project.technologies.join(", ")}</p>
            <a target="_blank" rel="noopener noreferrer" href={project.link} style={linkStyling} className="view-project-btn">
                <FontAwesomeIcon icon={faArrowTrendUp} /> View Project
            </a>
        </div>
    </div>
  )
}
