import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import dataInfo from "../Data"

export default function Header(){
    return (
        <div className="header-component">
            <a target="_blank" href={dataInfo.socialMedia.github} rel="noreferrer"> 
                <FontAwesomeIcon icon={faGithub} /> Github</a>
            <a target="_blank" href={dataInfo.socialMedia.linkedIn} rel="noreferrer"> 
                <FontAwesomeIcon icon={faLinkedin} /> LinkedIn</a>
            <a target="_blank" href={dataInfo.socialMedia.twitter} rel="noreferrer"> 
                <FontAwesomeIcon icon={faTwitter} /> X</a>
        </div>
    )
}