import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Landing from "./pages/Landing"
import ProjectDisplay from "./pages/ProjectDisplay"

export default function App() {

  return (

    <div className="landing-section">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/:projectTitle" element={<ProjectDisplay />} />
            </Routes>
        </BrowserRouter>
    </div>

  )
}
