const dataInfo =
    {
        socialMedia: {
                    github: "https://github.com/mikegag",
                    linkedIn: "https://www.linkedin.com/in/michael-gagliardi-b662b3188/",
                    twitter: "https://twitter.com/MikeCodesStuff"
        },

        projects: [
                {
                    id: "skillja",
                    link: "https://www.skillja.ca/",
                    title: "SkillJa (beta)",
                    image: "skillja.png",
                    description: "A Job Application Tracking Tool designed specifically for Developers.",
                    technologies: ["React", "TypeScript", "Tailwind", "Python-Django", "PostgreSQL", "Heroku"]
                },
                {
                    id: "jobCache",
                    link: "https://job-cache-server-d10d775041b7.herokuapp.com/",
                    title: "JobCache",
                    image: "jobcache.png",
                    description: "A Job Application Tracking Tool designed specifically for Developers.",
                    technologies: ["React", "Python-Flask", "MongoDB", "API's", "Heroku"]
                },
                {
                    id: "quizApp",
                    link: "https://quiz-app-neon-kappa.vercel.app/",
                    title: "Quizzical",
                    image: "quiz.png",
                    description: "A React based quiz app which that your knowledge on a plethora of topics.",
                    technologies: ["HTML", "CSS", "Javascript", "React", "API's"]
                }
        ],

        personalDetails: {
                    education: {
                        certifications: ["BA - Digital Media (York University, 2023)", 
                                        "B.Ed - Concurrent Education (York University, 2023)",
                                        "Ontario Certified Teacher (Ontario College of Teachers, 2023)",
                                        "Frontend Developer Bootcamp (Scrimba, 2024)",
                                        "Full Stack Developer Certificate (Toronto Metropolitan University, 2024)"],

                        skills: ["Javascript", "React", "TypeScript", "Python", "Django", "MongoDB", "PostgreSQL"],

                        completeSkillSet: ["HTML5", "CSS", "Javascript ES6+", "NodeJS", "React", "TypeScript", "Tailwind", "Python", "Flask",
                                            "Django", "PostgreSQL", "MongoDB", "Git", "Github", "Heroku", "Vercel", "Figma", "MacOS", "Windows", "Linux-Ubuntu"],
                    },

                    biography: { 
                        firstParagraph:`Teacher Turned Full-Stack Developer. 
                        I enjoy crafting visually engaging & user-friendly web applications, 
                        as well as solving complex problems through code. 
                        Checkout my Github to see what I'm currently working on!`,

                        secondParagraph: `Outside of coding some of my hobbies include running marathons, 
                        listening to audiobooks, golfing, & trying new cooking recipes.`
                    },

                    email: "contact@m-gagliardi.com"
        }
    }

export default dataInfo