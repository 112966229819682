import React, { useState, useEffect } from "react"
import computerImage from "../assets/computer.svg"
import dataInfo from "../Data"

export default function MainTitle() {
    const listOfSkills = dataInfo.personalDetails.education.skills
    const [currentSkillIndex, setCurrentSkillIndex] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSkillIndex((prevIndex) => (prevIndex + 1) % listOfSkills.length)
        }, 1200)

        return () => {
            clearInterval(timer)
        }
    }, [])
    
    const currentSkill = listOfSkills[currentSkillIndex]

    const imageStyling = {
        margin:"1em auto", 
        width:"250px",
        paddingRight:"0.75em",
        zIndex:"1"
    }

    return (
        <div className="main-title-component" style={{fontFamily:"caveat"}}>
            <div className="main-title-top"><h1>MICHAEL</h1></div>
            <div className="main-title-middle">
                <h1>FULLSTACK</h1>
                <p>{currentSkill}</p>
            </div>
            <div className="main-title-bottom"><h1>DEVELOPER</h1></div>
            <img src={computerImage} alt="Michael G. smiling" style={imageStyling}/>
        </div>
    )
}
